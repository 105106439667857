import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, LinkBox, Link, Section } from "@quarkly/widgets";
import { MdEmail } from "react-icons/md";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "#3f24d8",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0",
	"id": "contact"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 30px 50px 30px",
			"background": "--color-light",
			"display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"margin": "0px 0px 80px 0px",
			"md-flex-direction": "column",
			"md-align-items": "stretch",
			"md-margin": "0px 0px 50px 0px",
			"md-padding": "30px 30px 30px 30px",
			"sm-padding": "20px 15px 20px 15px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "60%",
			"md-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"sm-margin": "0px 0px 18px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"color": "--dark",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 12px 0px",
			"children": "Виникли проблеми з ПК?"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--dark",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": "Опишіть вашу проблему і наша служба підтримки опише варінти її вирішення."
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"padding": "15px 25px 15px 25px",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-dark",
			"background": "rgba(247, 251, 255, 0.12)",
			"sm-padding": "10px 15px 10px 15px",
			"href": "mailto:contact@risonda.live"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdEmail,
			"size": "24px",
			"color": "--dark",
			"margin": "0px 8px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "--dark",
			"children": "contact@risonda.live"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				вул. Соборна 217Б, Рівне
				<br />
				{" "}Рівненська область, Україна, 33000
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Контакти"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+38 098 122 80 30",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"children": "+38 098 122 80 30"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@risonda.live",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "#f5eceb",
			"children": "contact@risonda.live"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--dark",
			"md-margin": "0px 0px 20px 0px",
			"children": "Години Роботи"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "ПН-ПТ  (10:00 – 18:00)"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "СБ-НД - вихідні"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "risonda.live © 2023 Всі права захищено."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<LinkBox {...override("linkBox")}>
				<Icon {...override("icon")} />
				<Text {...override("text2")} />
			</LinkBox>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text5")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
				<Text {...override("text8")} />
			</Box>
		</Box>
		<Text {...override("text9")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;